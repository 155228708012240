

<!-- Anmelden Formular -->
<div>
    <div class="col custom">
        <!-- Anmelden -->
        
        <div style="opacity: 1; padding-right:0px;" [style.padding-left]="getPaddingLeftOuter()" [style.padding-right]="getPaddingRightOuter()" class="wpb_column column_container vc_column_container col has-animation inherit_tablet inherit_phone  instance-2 animated-in padding-4-percent" data-using-bg="true" data-t-w-inherits="small_desktop"
        data-bg-cover="" data-padding-pos="all" data-has-bg-color="true" data-bg-opacity="1" data-hover-bg="" data-hover-bg-opacity="1" data-animation="fade-in" data-delay="0">
        <div [ngClass]="{'logincenter':reg !== 0}" class="vc_column-inner" style="max-width: 490px;  background-color: #f4f4f4;">
                <div class="column-bg-overlay-wrap" data-bg-animation="none">
                    <div class="column-bg-overlay"></div>
                </div>
      
                <div class="wpb_wrapper"  [style.padding-left]="getPaddingLeftInner()">
                    <!-- sprachauswahl -->
                    <app-sprachauswahl (onReloadData)="this.reloadData()" ></app-sprachauswahl>

                    <div class="wpb_text_column wpb_content_element" style="width: 125px;">
                        <div class="wpb_wrapper" [style.display]="getDisplay()">
                            <h3>{{'LOGIN.ANMELDEN' | translate }}</h3>
                            <div class="divider-wrap" data-alignment="default">
                                <div style="margin-top: 25px; width: 20px; height: 1px; margin-bottom: 25px;" data-width="20%" data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
                            </div>
                        </div>
                    </div>

         
                    <form *ngIf="!this.authService.currentUser" [formGroup]="myForm" [style.max-width]="getMaxWidth()">

                        <dx-form [(formData)]="myForm" validationGroup="loginData" [showValidationSummary]="true" [colCount]="1" [labelLocation]="'top'" requiredMark="*" [showColonAfterLabel]="true" style="opacity: 1;">

                            <dxi-item itemType="group">
                                <!-- Benutzer -->
                                <dxi-item dataField="benutzername" type="required" editorType="dxTextBox" [editorOptions]="userfieldOptions">
                                    <dxo-label text="{{'LOGIN.BENUTZERNAME' | translate }}"></dxo-label>
                                    <dxi-validation-rule type="required" message="{{'LOGIN.BENUTZERNAME_EINGEBEN' | translate }}"></dxi-validation-rule>
                                    <dxi-validation-rule type="custom"  message="{{'LOGIN.BENUTZERNAME_UNGUELTIGE_ZEICHEN' | translate }}" [validationCallback]="benutzernameKontrolle"></dxi-validation-rule>
                                    <dxi-validation-rule type="stringLength" [min]="1" message="{{'LOGIN.BENUTZERNAMEMINDESTENSZEICHEN' | translate }}"></dxi-validation-rule>
                                    <dxi-validation-rule type="stringLength" [max]="64" message="{{'LOGIN.MAXIMAL_64_ZEICHEN' | translate }}"></dxi-validation-rule>
                                </dxi-item>
                                <!-- Passwort -->
                                <dxi-item dataField="password" type="required" [editorOptions]="passwordfieldOptions">
                                    <dxo-label text= "{{'LOGIN.PASSWORT' | translate }}"></dxo-label>
                                    <dxi-validation-rule type="required" message="{{'LOGIN.PASSWORT_EINGEBEN' | translate }}"></dxi-validation-rule>
                                </dxi-item>
                            </dxi-item>

                            <!-- <button (click)="executeImportantAction()">Important action</button> -->

                            <!-- Anmelden Button -->
                            <!-- <dxi-item itemType="simple" [colSpan]="3">
                                <div *dxTemplate style="text-align: right;">
                                    <dx-button text="anmelden" (onClick)="okLogin($event)" [template]="'buttonTemplate'" validationGroup="loginData">
                                        <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                                            <div class="nectar-button large regular accent-color regular-button has-icon"  data-hover-text-color-override="#fff" style="background-color: rgb(122, 181, 29) !important;visibility: visible;margin-bottom:0px;display:block;"><i class="icon-button-arrow"></i><span>{{buttonData.text}}</span></div>
                                        </div>
                                    </dx-button>
                                </div>
                             </dxi-item> -->
                            <dxi-item itemType="simple" [colSpan]="3">
                                <div *dxTemplate style="text-align: right;">
                                    <dx-button text="{{'LOGIN.ANMELDEN_KLEIN' | translate }}" (onClick)="okLogin($event)" [template]="'buttonTemplate'" validationGroup="loginData">
                                        <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                                            <app-button [buttonData]="buttonData"></app-button>
                                        </div>
                                    </dx-button>
                                </div>
                             </dxi-item>

                             <dxi-item>
                                 <div class="nectar-cta " data-color="default" data-using-bg="false" data-display="block" data-style="underline" data-alignment="left" data-text-color="custom">
                                     <h5 style="color: #000000; padding-bottom:10px; float: right;"> <span class="text"> </span>
                                         <span class="link_wrap">
                                         <a href="javascript: void(0);" (click)="onResetPassword($event)" class="link_text" style="border-color: #000000;" >{{ 'LOGIN.BENUTZERNAME_PASSWORT_VERGESSEN' |translate }}</a>
                                         </span>
                                     </h5>
                                 </div>
                             </dxi-item>
                        </dx-form>
                    </form>
                    <div *ngIf="this.authService.currentUser" style="text-align: center;">
                        <!-- <dxi-item itemType="simple" [colSpan]="3">
                            <div *dxTemplate style="text-align: right;"> -->
                                <dx-button #fortfahren [(text)]="fortfahrentext" (onClick)="okfortfahren($event)" [template]="'buttonTemplate'" >
                                    <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                                        <app-button [buttonData]="buttonData"></app-button>
                                    </div>
                                </dx-button>
                                <dx-button style="height: 42px; width: 230px;" [text]="this.translate.instant('LOGIN.KONTOWECHSELN')" (onClick)="kontowechseln($event)" icon="user">
                        
                                </dx-button>
                            <!-- </div>
                         </dxi-item> -->
                    </div>
                    <br>



                    <div *ngIf="iexplorer" class="card-block">
                        <p class="text-muted">{{ 'LOGIN.BROWSERUNTERSTÜTZUNG' |translate }}<br><br> {{ 'LOGIN.BROWSERVERWENDUNG' |translate }} <a href="https://www.google.com/chrome/" class="link_text">{{ 'LOGIN.BROWSERCHROME' |translate }}</a> oder <a href="https://www.mozilla.org/de/firefox/new/" class="link_text">{{ 'LOGIN.BROWSERFIREFOX' |translate }}</a> {{ 'LOGIN.BROWSERVERWENDUNGENDE' |translate }}</p>
                    </div>

                </div>
            </div>
    </div>
</div>
</div>


<dx-popup title="{{'LOGIN.ANMELDEN' | translate }}" [width]="popupWidth" [height]="popupHeight" [showTitle]="true" [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="upgradeVisible" contentTemplate="popupContent">
    <div *dxTemplate="let data of 'popupContent'">
        <dx-scroll-view>
            <p>{{'LOGIN.DATENSCHUTZERKLAERUNGANMELDEN' | translate}}</p>
            <form  style="max-width: 650px;">

                <dx-form [(formData)]="daten" validationGroup="upgradeData" [showValidationSummary]="true" [colCount]="3" [labelLocation]="'top'" requiredMark="*" [showColonAfterLabel]="true" style="opacity: 1; margin-top: 0px !important;">

                    <dxi-item *ngIf="!this.nurgdsgvo" [dataField]="'email'" [colSpan]="3" horizontalAlignment="right" caption="E-Mail"  editorType="dxTextBox">
                        <dxi-validation-rule  type="required" message="{{ 'LOGIN.EMAILEINGABE' |translate }}"></dxi-validation-rule>
                        <dxi-validation-rule type="email" message="{{ 'LOGIN.EMAILUNGÜLTIG' |translate }}"></dxi-validation-rule>                
                    </dxi-item>
                    <dxi-item [dataField]="'datenschutz'" [colSpan]="2" horizontalAlignment="right" caption="{{'MITGLIEDWERDEN.DATENSCHUTZ' | translate}}"  editorType="dxCheckBox" type="required" [editorOptions]="{text: this.translate.instant('LOGIN.DATENSCHUTZBESTIMMUNGENTEXT') ,value: false}">
                        <dxi-validation-rule  type="compare" [comparisonTarget]="datenschutzComparison" message="{{ 'MITGLIEDWERDEN.DATENSCHUTZBESTIMMUNGENAKZEPTIEREN' |translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item [colSpan]="1" itemType="simple" >
                        <a href="javascript: void(0);" (click)="openDSGVO(dsgvoItem)" class="link_text">{{ 'LOGIN.DATENSCHUTZBESTIMMUNGEN' |translate }}</a><span class="dx-field-item-required-mark">&nbsp;*</span>
                    </dxi-item>
                    <dxi-item *ngIf="this.mitnewsletter && !this.nurgdsgvo" [dataField]="'newsletter'" [colSpan]="3" horizontalAlignment="right" caption="Newsletter"  editorType="dxCheckBox" [editorOptions]="{text: this.translate.instant('LOGIN.EMAILINFORMIERT'),value: true}">
                    </dxi-item>
                    <dxi-item itemType="empty"  [colSpan]="3" >
                    </dxi-item>
                    <dxi-item *ngIf="!this.mitnewsletter || this.nurgdsgvo" itemType="empty"  [colSpan]="3">
                    </dxi-item>
                    <dxi-item *ngIf="!this.mitnewsletter || this.nurgdsgvo" itemType="empty"  [colSpan]="3">
                    </dxi-item>
                    <!-- <dxi-item temType="group" [colSpan]="2" [colCount]="2"> -->
                        <dxi-item *ngIf="upgradeVisible" itemType="simple" [colSpan]="2" horizontalAlignment="right" style="float: right;">
                            <dx-button text="weiter"  (onClick)="onUpgrade($event)" [template]="'buttonTemplate'"  style="margin: 5px;" validationGroup="upgradeData">
                                <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                                    <app-button [buttonData]="buttonData"></app-button>
                                </div>
                            </dx-button>
                        </dxi-item>
                        <dxi-item *ngIf="upgradeVisible" itemType="simple" [colSpan]="4" horizontalAlignment="right" style="float: right;">
                            <dx-button text="abbrechen" (onClick)="onUpgradeAbbrechen($event)" [icon]="'clear'"  style="margin: 5px;" ></dx-button>
                        </dxi-item>
                    <!-- </dxi-item> -->


                </dx-form> 
            </form>
        </dx-scroll-view>


    </div>
</dx-popup>



<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" [message]="this.translate.instant('LOGIN.DETAILS_LADEN')">
</dx-load-panel>
